.dropdown-toggle-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: none;
  border: none;
  border-bottom: 1px solid #785068;
  cursor: pointer;
  color: #000;
  padding: 0;
  width: 100%;
}

.dropdown-toggle-icon {
  transform: rotate(180deg);
  transition: transform 0.3s;
}

.dropdown-toggle-icon.toggle-open {
  transform: rotate(270deg);
  transition: transform 0.3s;
}

.dropdown-content {
  opacity: 0;
  display: none;
  height: 0;
  max-height: 0;
}
.dropdown-content.open {
  height: auto;
  display: block;
  opacity: 1;
  padding: 0;
  max-height: 10000px;
  transition: max-height 1s ease-out, opacity 0.3s ease-out;
}
.dropdown-subarea-title {
  display: block;
  margin-top: 1rem;
  font-weight: 600;
}
.dropdown-subarea-description {
  margin-bottom: 2rem;
}
