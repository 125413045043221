.contact-modal-wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}
.contact-modal-container {
  background-color: #fff;
  border-top-left-radius: 0.75rem;
  border-top-right-radius: 0.75rem;
  box-shadow: 0 0px 10px rgb(0 0 0 / 10%);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 90%;
  padding: 1rem;
}

.contact-modal-content-container {
  overflow-y: auto;
  height: 100%;
  margin-top: 2rem;
  padding-bottom: 3rem;
}

.info-container {
  margin-bottom: 2rem;
}

.input-container {
  position: relative;
  margin-bottom: 2rem;
  height: 46px;
  width: 100%;
}

label {
  position: absolute;
  top: -6px;
  font-size: 0.75rem;
  left: 6px;
  background: #fff;
  border-radius: 2px;
  padding: 0 4px;
  font-weight: 600;
}

input {
  border: 1px solid #b3b1b2;
  border-radius: 4px;
  height: 46px;
  width: 100%;
  padding: 0 1rem;
}

.textarea-container {
  position: relative;
  margin-bottom: 1rem;
}

.textarea {
  border: 1px solid #b3b1b2;
  border-radius: 4px;
  width: 100%;
  min-height: 156px;
  padding: 1rem;
}

input:focus,
.textarea:focus {
  border: 1px solid #1e5b73;
}

input:focus:required:invalid,
.textarea:focus:required:invalid {
  border: 1px solid #f25775;
}
input:required:valid,
.textarea:required:valid {
  border: 1px solid #1ba632;
}

.input-error {
  border: 1px solid #f25775 !important;
}

.input-error-message {
  position: absolute;
  left: 0;
  bottom: -1.5rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.attention-icon {
  position: absolute;
  top: 0.875rem;
  right: 1rem;
}

.folkets-button.primary {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

button {
  width: 100%;
}

.loading-container,
.loading-content-container {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

.loading-icon {
  box-shadow: 0px 0px 1px 1px #0000001a;
}

.loading-text {
  font-size: 0.75rem;
}

.contact-modal-close-button-container {
  position: absolute;
  left: 1rem;
  right: 16px;
  display: flex;
  justify-content: flex-end;
}

.contact-modal-close-button {
  background: none;
  border: none;
  width: fit-content;
}

@media only screen and (min-width: 992px) {
  .contact-modal-container {
    border-radius: 0.75rem;
    max-width: 800px;
    max-height: 690px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .contact-modal-content-container {
    height: 100%;
    margin-top: 0;
    padding-bottom: 0;
  }

  .flex-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
  }

  .button-container {
    display: flex;
    flex-direction: row-reverse;
    gap: 0.5rem;
  }

  button {
    width: max-content;
  }

  .folkets-button.primary {
    margin-bottom: 0;
    margin-top: 0;
  }
  .contact-modal-close-button {
    display: none;
  }
  .contact-form-footer-container {
    display: flex;
    justify-content: flex-end;
  }
}

.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }
  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}
