.ig-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 1rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.ig-container::-webkit-scrollbar {
  display: none;
}
.ig-link {
  padding: 1rem 0.5rem;
}
.ig-video {
  max-width: 300px;
  max-height: 300px;
  width: 300px;
  height: 300px;
  object-fit: cover;
  object-position: top;
}
.ig-image-container {
  max-width: 300px;
  min-width: 300px;
  max-height: 300px;
  min-height: 300px;
  box-shadow: 0px 4px 12px rgb(0 0 0 / 16%);
}
.ig-image-container:hover,
.ig-video:hover {
  filter: brightness(0.9);
}

.ig-image {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.scroll-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
}

.scroll-button {
  display: none;
}

@media only screen and (min-width: 992px) {
  .scroll-button {
    display: block;
    border: none;
    background-color: #fff;
    min-width: 2.875rem;
    cursor: pointer;
  }
  .chevron-icon {
    min-width: 1.625rem;
    width: 1.625rem;
  }
  .chevron-icon.right {
    transform: rotate(180deg);
  }
}
