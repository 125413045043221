.ak-page-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7e4be;
  padding: 1rem;
  min-height: 240px;
  text-align: center;
}

.ak-page-container {
  max-width: 992px;
  margin: 0 auto;
  margin-bottom: 3rem;
  padding: 1rem;
}

.ak-page-container ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100px auto;
  grid-column-gap: 1rem;
  column-gap: 1rem;
  row-gap: 0.25rem;
  word-break: break-word;
}

.ak-page-contact .title {
  font-weight: 600;
}

.ak-page-contact a {
  color: #000;
  text-decoration: none;
}

.ak-page-contact a:hover {
  text-decoration: underline;
}
