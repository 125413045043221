.law-hero-section {
  text-align: center;
  margin-bottom: 3rem;
  background-color: #f7e4be;
  padding: 1rem;
  padding-bottom: 3rem;
  min-height: 240px;
}
.law-hero-section p {
  margin: 0 auto;
  max-width: 768px;
}

.law-topic-container {
  max-width: 768px;
  margin: 0 auto;
}
