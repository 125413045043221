.toast-container {
  display: flex;
  gap: 0.5rem;
  border-radius: 4px;
  padding: 0.5rem 1rem;
}

.toast-container.error {
  border: 2px solid #f25775;
}
.toast-container.success {
  border: 2px solid #1ba632;
}
