.hero-container {
  background-color: #f7e4be;
  min-height: 670px;
}

.hero-image-container {
  min-height: 260px;
}

.hero-image {
  background-color: #eee;
  border: none;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.hero-contact {
  display: none;
}

.hero-text-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-self: center;
  font-weight: 600;
  min-height: 250px;
  padding: 1rem;
  padding-bottom: 2rem;
}
.hero-text-container h1 {
  margin-bottom: 0;
}
.hero-text-container p {
  font-weight: 400;
  font-size: 1.125rem;
}

@media only screen and (min-width: 992px) {
  .hero-container {
    min-height: auto;
  }
  .hero-content-container {
    display: flex;
    justify-content: space-between;
    gap: 2rem;
    width: 100%;
    max-width: 1920px;
    margin: 0 auto;
  }

  .hero-image-container {
    min-height: 370px;
    width: 50%;
  }

  .hero-image {
    height: 100%;
  }

  .hero-text-container {
    width: 50%;
    padding-left: 0;
  }

  .hero-contact {
    display: block;
    align-self: flex-end;
  }
}

@media only screen and (min-width: 1921px) {
  .hero-text-container {
    padding: 0;
  }
}
