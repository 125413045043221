.laws-container {
  padding: 0 1rem;
}

.law-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #785068;
  border-radius: 8px;
  min-height: 150px;
  width: 100%;
  height: 100%;
  margin-bottom: 1rem;
  text-decoration: none;
  word-break: break-word;
}
.law-container h3 {
  color: #fff;
  font-weight: 600;
  text-align: center;
  margin: 0;
}

.law-container span {
  color: #fff;
}

@media only screen and (min-width: 576px) {
  .laws-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
  }
  .law-container {
    width: 48%;
  }
}

@media only screen and (min-width: 992px) {
  .laws-container {
    flex-wrap: nowrap;
    padding: 0;
  }
  .law-container {
    min-width: auto;
    max-width: none;
    width: 20%;
  }
}
