.employer-container {
  padding: 1rem;
  background-color: #fff;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);
  border-radius: 5px;
  margin-bottom: 1rem;
  min-height: 475px;
  text-align: left;
}

.employer-content-container.open {
  position: relative;
}

.employer-profile-image {
  aspect-ratio: 1 / 1;
  width: 100%;
  object-fit: cover;
  object-position: 0 -25px;
  border-radius: 8px;
}

.employer-name-container {
  text-align: center;
  margin: 2rem 0;
}

.employer-header {
  margin: 0;
  text-align: center;
}

.employer-text-container {
  position: absolute;
  word-break: break-word;
  background-color: #fff;
  bottom: 0;
  top: unset;
  height: 90%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
  padding: 1rem;
}

.employer-text-content-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
}

.employer-text-content-wrapper {
  margin-top: 2rem;
}

.employer-details {
  margin-bottom: 1rem;
}
.employer-language-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  margin-bottom: 1rem;
}
.employer-language-title {
  font-weight: 600;
}
.employer-language-icon-container {
  display: flex;
  align-items: center;
  font-weight: 400;
}
.employer-details-item-container {
  display: flex;
  align-items: center;
  gap: 4px;
  margin-bottom: 0.5rem;
}
.employer-details-item-container .title {
  font-weight: 600;
}
.employer-details-item-container a {
  color: #000;
  text-decoration: none;
}

.employer-close-button-container {
  position: absolute;
  left: 1rem;
  right: 16px;
  display: flex;
  justify-content: flex-end;
}

.employer-close-button {
  background: none;
  border: none;
  width: fit-content;
}

.read-more-button {
  width: 100%;
  max-width: none;
}

.globe-icon {
  width: 20px;
}

@media only screen and (min-width: 768px) {
  .employer-profile-image {
    height: 300px;
  }
  .read-more-button-container {
    display: flex;
    justify-content: flex-end;
  }
  .read-more-button {
    width: max-content;
    max-width: 380px;
  }
  .employer-text-content-container {
    position: absolute;
    z-index: 0;
  }
  .employer-text-container {
    position: unset;
    height: 100%;
    overflow-y: auto;
    border-radius: 0;
    padding: 0;
  }
  .employer-text-container button {
    display: none;
  }

  .employer-close-button {
    display: none;
  }
}
