.not-found-page-container {
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -60%);
  width: 100%;
  padding: 1rem;
}

.not-found-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  max-width: 550px;
}

.not-found-title {
  font-size: 4rem;
}
.not-found-description {
  margin-bottom: 2rem;
}

.folkets-button.not-found {
  width: 100%;
}

@media only screen and (max-height: 667px) {
  .not-found-page-container {
    top: 35%;
    transform: translate(-50%, -65%);
  }
}

@media only screen and (min-width: 992px) {
  .not-found-button-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .folkets-button.not-found {
    width: max-content;
  }
}
