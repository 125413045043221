.card-container {
  width: 100%;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);
  max-width: 992px;
  margin: 0 auto;
  margin-bottom: 2rem;
  min-height: 320px;
}

.card-image-container img {
  width: 100%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.card-content-container {
  padding: 1rem;
}

.card-download-button {
  text-align: center;
  text-decoration: none;
}

@media only screen and (min-width: 768px) {
  .card-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
  }

  .reverse {
    flex-direction: row-reverse;
  }

  .card-content-container {
    align-self: flex-start;
    width: 100%;
  }

  .card-image-container {
    width: 40%;
    flex-shrink: 0;
  }
  .card-image-container img {
    border-radius: 0;
  }

  .card-download-button {
    width: max-content;
  }
}
