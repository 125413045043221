.about-section {
  text-align: center;
  margin-bottom: 3rem;
  background-color: #f7e4be;
  padding: 1rem;
  padding-bottom: 3rem;
  min-height: 240px;
}
.about-section p {
  margin: 0 auto;
  max-width: 768px;
}
.employees-section {
  min-height: 491px;
  text-align: center;
  margin-bottom: 3rem;
}

.loading-container {
  min-height: 491px;
}
@media only screen and (min-width: 768px) {
  .employees-container {
    align-items: center;
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 2rem auto;
    max-width: 1098px;
    width: 100%;
  }
}
