.folkets-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  min-height: 2.25rem;
  padding: 0.5rem 1rem;
  word-break: break-word;
}

.folkets-button img {
  width: 12px;
}

.cta {
  background-color: #f8a6d7;
  border: none;
  border-radius: 2.25rem;
  color: #000;
}

.primary {
  background-color: #785068;
  border: none;
  border-radius: 0.3125rem;
  color: #fff;
}

.primary:hover {
  filter: brightness(90%);
}

.secondary {
  color: #000;
  background-color: #fff;
  border: 1px solid #785068;
  border-radius: 0.3125rem;
}

.secondary:hover {
  filter: brightness(95%);
}
