.footer-container {
  background-color: #f7e4be;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  font-weight: 600;
  text-align: center;
  line-height: 1.5;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  padding: 3rem 1rem 5rem;
}

.footer-content a {
  color: #000;
  text-decoration: none;
}

.footer-content a:hover {
  text-decoration: underline;
}

.footer-container .social-media-container .icon-container {
  margin-top: 0.5rem;
}

.footer-container .social-media-container .icon-container img {
  margin: 0 0.25rem;
}

.advokatsamfundet {
  margin-top: 1rem;
}

@media only screen and (min-width: 992px) {
  .footer-content {
    flex-direction: row;
    align-items: end;
    padding: 2rem 1rem;
  }

  .footer-container .social-media-container {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    width: 50%;
    margin: 0;
  }

  .footer-container .footer-contact-details {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    justify-content: flex-end;
    align-items: flex-end;
  }

  .footer-contact-form {
    display: none;
  }
}
