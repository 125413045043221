@import "./assets/styles/buttons.css";

@font-face {
  font-family: "MontserratVariableFont";
  src: url("./assets/font/Montserrat-VariableFont_wght.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "MontserratRegularFont";
  src: url("./assets/font/Montserrat-Regular.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "NotoSansRegular";
  src: url("./assets/font/NotoSans-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  /* font-family: "MontserratVariableFont", "MontserratRegularFont", "Roboto",
    "Helvetica Neue", sans-serif; */
  font-family: "NotoSansRegular", "Roboto", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

.app {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  max-width: 1920px;
  margin: 0 auto;
  padding: 1rem;
  padding-top: 0;
  padding-bottom: 6rem;
}

.floating-button {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  width: auto;
  height: 46px;
  z-index: 1;
  min-width: 150px;
}

@media only screen and (min-width: 992px) {
  .floating-button {
    display: none;
  }
}

@media only screen and (min-width: 1921px) {
  .page-container {
    padding: 0;
    padding-bottom: 6rem;
  }
}
