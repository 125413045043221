.language-dropdown-container {
  position: relative;
  background-color: #fff;
}
.language-dropdown-content {
  position: absolute;
  right: 0;
  height: 0;
  background-color: #fff;
  opacity: 0;
  border-radius: 0.25rem;
  box-shadow: 0px 4px 14px rgb(0 0 0 / 16%);
  height: auto;
  min-width: 200px;
  opacity: 1;
  padding: 1rem 0.5rem;
}
.language-dropdown-content ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.language-dropdown-content li {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
}
.language-dropdown-content li:hover {
  background-color: #f8a6d7;
  border-radius: 4px;
}
.language-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  background-color: #fff;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
}
.language-button span {
  display: none;
}

@media only screen and (min-width: 992px) {
  .language-button span {
    display: inline;
  }
}
