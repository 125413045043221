.header-container {
  padding: 0.5rem 1rem;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 1;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  max-width: 1920px;
}

nav {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  background-color: #fff;
  width: 100%;
  transform: translateX(100%);
  transition: transform 0.3s ease-out, padding 0s 0.3s ease-out;
}

nav.open {
  padding: 1rem;
  padding-top: 5rem;
  transform: translateX(0);
  transition: transform 0.3s ease-out;
}

.menu-icon {
  cursor: pointer;
  width: 28px;
  height: 20px;
  position: relative;
  transform: rotate(0deg);
  transition: 0.5s ease-in-out;
  cursor: pointer;
  z-index: 1;
}

.menu-icon span {
  display: block;
  position: absolute;
  height: 4px;
  width: 100%;
  background: #000;
  border-radius: 2px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.menu-icon span:nth-child(1) {
  top: 0px;
}

.menu-icon span:nth-child(2) {
  top: 8px;
}

.menu-icon span:nth-child(3) {
  top: 16px;
}

.menu-icon.open span:nth-child(1) {
  top: 8px;
  width: 0%;
  left: 50%;
}

.menu-icon.open span:nth-child(2) {
  transform: rotate(45deg);
}

.menu-icon.open span:nth-child(3) {
  top: 8px;
  transform: rotate(-45deg);
}

.menu-close-icon {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 63px;
  cursor: pointer;
}

.nav-list {
  font-weight: 600;
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.nav-list-item.cta {
  display: none;
}

.nav-link {
  color: #000;
  text-decoration: none;
  border-bottom: 3px solid #f8a6d7;
}

.nav-link:hover {
  border-bottom: 3px solid #f8a6d7;
}

.logo {
  width: 130px;
}

.logo img {
  width: 100%;
}

.hide {
  visibility: hidden;
}

.language-menu-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mobile {
  display: block;
}

.desktop {
  display: none;
}

@media only screen and (min-width: 992px) {
  .menu-icon,
  .menu-close-icon {
    display: none;
  }

  nav {
    position: unset;
    background: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 1rem;
    transform: translateX(0);
  }

  nav.open {
    padding: 0;
  }

  .nav-list {
    flex-direction: row;
  }

  .nav-list-item.cta {
    display: block;
  }

  .nav-link {
    border: none;
  }

  .hide {
    visibility: visible;
  }

  .language-menu-container {
    display: none;
  }

  .desktop {
    display: block;
  }
}
