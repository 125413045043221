.section {
  margin-bottom: 4rem;
}
.section-title {
  padding-left: 1rem;
  margin-bottom: 0;
}
.section-description {
  font-size: 0.875rem;
  padding-left: 1rem;
  margin-bottom: 1rem;
}
.horizontal-scroll-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  overflow-x: auto;
  overflow-y: hidden;
  scroll-behavior: smooth;
}

@media only screen and (max-width: 991px) {
  .home-page-container {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (min-width: 992px) {
  .section-title,
  .section-description {
    padding: 0;
  }
  .horizontal-scroll-container {
    flex-wrap: wrap;
  }
}
